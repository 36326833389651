import React, {useState, useEffect} from 'react';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { DeFiWeb3Connector } from 'deficonnect';
// import {Row, Col} from 'react-bootstrap';
import {providers} from 'ethers';
import Web3 from 'web3';
import Modal from 'react-modal';

// import pngMM from "../assets/gfx/connect/metamask.png";
// import pngWC from "../assets/gfx/connect/walletconnect.png";
// import pngCDC from "../assets/gfx/connect/cdcdefi.png";

import svgMM from "../assets/gfx/connect/metamask.svg";
import svgCDC from "../assets/gfx/connect/cdc.svg";
import svgWC from "../assets/gfx/connect/walletconnect.svg";

import './css/Web3Provider.scss';

Modal.setAppElement("#root");

const configObject = {
   provider: {
       rpc: {
           25: "https://evm.cronos.org",
           338: "https://evm-t3.cronos.org"
       },
   }
}

const customStyles = {
   content: {

   },
 };

declare const window: any;

interface Web3ContextObject {
   accounts: string[];
   provider: WalletConnectProvider | providers.Web3Provider | unknown;
   signer: providers.JsonRpcSigner | undefined;
   web3Obj?: Web3;
   connected: boolean;
   connectWallet(): void;
   disconnectWallet(): void;
}

const shortenAddress = (addr: string) => {
   const returnAddr = addr.slice(0, 5) +  " . . . " + addr.slice(-5);
   return returnAddr;
}

const Web3Context = React.createContext<Web3ContextObject>({
   accounts: [],
   provider: {},
   signer: undefined,
   connected: false,
   // eslint-disable-next-line @typescript-eslint/no-empty-function
   connectWallet: () => {},
   // eslint-disable-next-line @typescript-eslint/no-empty-function
   disconnectWallet: () => {},
});

const useWeb3Context = () => React.useContext(Web3Context);

const Web3Provider = ({children}: any) => {
   let prov: WalletConnectProvider | providers.Web3Provider;

   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);

   const [web3Object, setWeb3Object] = useState<Web3ContextObject>({
      accounts: [],
      provider: {},
      signer: undefined,
      connected: false,
      connectWallet: () => {connectWallet()},
      disconnectWallet: () => {disconnectWallet()},
   });

   async function connectWallet() {
      try {
         setShow(true);
      } catch (error) {
         console.log(error);
      }
   }

   const disconnectWallet = () => {
      try {
         console.log("disconnecting");
         setWeb3Object(
            {
               ...web3Object,
               provider: {},
               accounts: [],
               connected: false,
            }
         );
      } catch (error) {
         console.log(error);
      }
   }

   useEffect(() => {
      setWeb3Object({
         ...web3Object,
         connectWallet: connectWallet,
         disconnectWallet: disconnectWallet,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleMetamask = async () => {
      disconnectWallet();
      prov = new providers.Web3Provider(window.ethereum);
      const acc = await prov.send("eth_requestAccounts", []);
      const sig = prov.getSigner();
      console.log(acc[0]);
      setWeb3Object({
         ...web3Object,
         provider: prov,
         accounts: acc,
         signer: sig,
         connected: true,
      });
      handleClose();
   }

   const handleWalletConnect = async () => {
      disconnectWallet();
      const provider = new WalletConnectProvider(configObject.provider);
      await provider.enable();

      prov = new providers.Web3Provider(provider);
      const acc = await prov.listAccounts();
      const sig = prov.getSigner();

      console.log(acc);
      setWeb3Object({
         ...web3Object,
         provider: prov,
         accounts: acc,
         signer: sig,
         connected: true,
      });
      handleClose();
   }

   const handleCDC = async () => {
      disconnectWallet();

      const connector = new DeFiWeb3Connector({
         supportedChainIds: [25],
         rpc: {
            25: "https://evm.cronos.org"
         },
         pollingInterval: 15000,
      });
      await connector.activate();
      const prov = await connector.getProvider();
      const web3 = new Web3(prov);
      const acc = await web3.eth.getAccounts();

      console.log(acc);
      setWeb3Object({
         ...web3Object,
         provider: prov,
         accounts: acc,
         web3Obj: web3,
         connected: true,
      });

      handleClose();
   }

   const providerList = [
      {
         icon: svgMM,
         title: 'MetaMask',
         text: 'Connect with MetaMask in your browser',
         action: handleMetamask
      },
      {
         icon: svgWC,
         title: 'WalletConnect',
         text: 'Scan with WalletConnect to connect',
         action: handleWalletConnect
      },
      {
         icon: svgCDC,
         title: 'Crypto.com DeFi Wallet',
         text: 'Connect with the CDC Defi Wallet',
         action: handleCDC
      }
   ]

   return(
      <Web3Context.Provider value={web3Object}>
         <Modal isOpen={show} onRequestClose={handleClose} style={customStyles} className='web3modal col-sm-8 col-xs-8' overlayClassName='web3modal-overlay'>
            <div id="providerModal" className='container'>
               <div className='row'>
               {
                  providerList.map((p,i) => {
                         return (
                             <div key={i} className='col-md-6 g-0 provider-btn flex-grow-1'>
                                <a href="#" onClick={p.action}>
                                   <div className='provider-logo'><img src={p.icon} /></div>
                                   <h5 className='provider-title'>{p.title}</h5>
                                   <span className='provider-text'>{p.text}</span>
                                </a>
                             </div>
                         )
                      }
                  )
               }
               </div>
            </div>
         </Modal>
         {children}
      </Web3Context.Provider>
   );
}

export {Web3Provider, Web3Context, useWeb3Context, shortenAddress};
