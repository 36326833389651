import {Container, Row, Col} from 'react-bootstrap';
// import { useWeb3Context } from './components/Web3Provider';

import FixedSection from './components/FixedSection';
import Web3 from "web3";
import React, { useEffect, useState } from "react";
import Web3Modal from "web3modal";
import pngConnect from './assets/gfx/mint/connect.png';
import pngMint from './assets/gfx/mint/mint2.png';
import pngInc from './assets/gfx/mint/inc.png';
import pngDec from './assets/gfx/mint/dec.png';
import { contract_address, contract_abi,chain_id, speedy_nodes, whitelist_addresses} from './config';
import fmPlaceholder from './assets/gfx/image/dont-rug-me-fobos.gif';
import GIFPFP from './assets/gfx/image/dont-rug-me-fobos.gif';

// import BigNumber from "bignumber.js";
import { ethers } from 'ethers';
// import { AbiItem } from '@celo/connect';

// import HiFiContractJSON from './contracts/HiddenFishDAO.json';

import './App.scss';
import SocialLinks from "./components/SocialLinks";

function App() {
  //const {web3Obj, connected, accounts, signer, connectWallet } = useWeb3Context();
  const [mintAmount, setAmount] = useState(1);
  // const [totalMinted, setTotalMinted] = useState("");
  const [publicsaleStarted, setpublicsaleStarted] = useState(false);
  const [privatesaleStarted, setprivatesaleStarted] = useState(false);
  // const [maxSupply,setMaxSupply]= useState("");
  const [isWalletConnected,setisWalletConnected]= useState(false);
  // const [newprice, setNewPrice] = useState(0);
  // const [walletLimit, setWalletLimit] = useState(0);
  
  const SIGNING_DOMAIN_NAME = "WEB3CLUB";
  const SIGNING_DOMAIN_VERSION = "1";

  async function connect_wallet(){

    if(Web3.givenProvider){
      const providerOptions = {
        /* See Provider Options Section */
      };

      const web3Modal = new Web3Modal({
        cacheProvider: true, // optional
        providerOptions // required
      });

      const provider = await web3Modal.connect();
      provider.on("accountsChanged", (accounts) => {
        console.log(accounts);
        window.location.reload();
      });

      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        console.log(chainId);
        window.location.reload();
      });

      // Subscribe to provider connection
      provider.on("connect", (info) => {
        console.log(info);
        window.location.reload();
      });

      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        console.log(error);
        window.location.reload();
      });

      const web3 = new Web3(provider);
      // const addresses = await web3.eth.getAccounts()
      //let address = addresses[0];
      //address = "0xE436c5c45D5B8b9DeDF37A59d9BE8AE7864b26CB";
      // setWeb3Global(web3);
      // setConnectedAddress(address);
      // setShortenAddress(address.slice(0, 4) + "..." + address.slice(address.length - 3, address.length))
      //alert("Connected")
      web3.eth.net.getId().then((result) => {

        console.log("Network id: "+result)
        if(result !== parseInt(chain_id)){ //result !== 1
          //alert("Wrong Network Selected. Select Goerli Test Network and Refresh Page");
          try{
            window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: web3.utils.toHex(chain_id) }]
            });
          }catch(e){
            alert("Error in Switching Chain, Make Sure you have added cronos chain in your web3 wallet.");
            window.location.reload();
          }

        }else{
          //   const contract = new web3.eth.Contract(contract_abi, contract_address);

          setisWalletConnected(true);
          // setStateOne(!stateOne);
          // fetch_Data_After_Wallet_Connect(address);
          // setShowPopup(false);
          // get_nfts(address);
          // get_data(web3, address)
        }
      })

    }else{
      alert("Web3 Not Found. Try refreshing if you have metamask installed.");
    }

  }
  async function fetch_data(){

    const web3 = new Web3(speedy_nodes);

    const contract = new web3.eth.Contract(contract_abi, contract_address);
    //await Web3.givenProvider.enable()

    contract.methods.publicSaleOpen().call((err,result) => {
      console.log("error: "+err);
      if(result != null){
        console.log("PublicSale")
        setpublicsaleStarted(result)
      }
    })

    contract.methods.privateSaleOpen().call((err,result) => {
      console.log("error: "+err);
      if(result != null){
        console.log("PrivateSale")
        setprivatesaleStarted(result)
      }
    })
    // contract.methods.maxSupply().call((err,result) => {
    //   console.log("error: "+err);
    //   if(result != null){
    //     setMaxSupply(result)
    //   }
    // })


    // contract.methods.price().call((err,result) => {
    //   console.log("error: "+err);
    //   if(result != null){
    //     setNewPrice(result)
    //   }
    // })
    // contract.methods.get_token_count().call((err,result) => {
    //     if(result != null){
    //         settokenCount(result)
    //     }
    // })
  }
  useEffect(() => {
   
    fetch_data();

  }, [])



  function check_whitelist(_to){
    for(let i=0;i<whitelist_addresses.length;i++){
      if(whitelist_addresses[i].wl_address.toLowerCase() == _to.toString().toLowerCase()){
        return whitelist_addresses[i].spots.toString();
      }
      if(i == whitelist_addresses.length - 1){
        return "false";
      }
    }
  }
  class SignHelper {

    constructor(contractAddress, chainId, signer){
      this.contractAddress = contract_address;
      this.chainId = chainId;
      this.signer = signer;
    }

    async createSignature(id){
      const obj = { id };
      const domain = await this._signingDomain();
      const types = {
        EIP712Domain: [
          {name: "id", type: "uint"}
        ]
      }
      const signature = await this.signer._signTypedData(domain, types,obj)
      return {...obj, signature}

    }
    static async getSign(contractAddress, chainId, id){
      let provider = new ethers.providers.Web3Provider(window.ethereum)

       await provider.send("eth_requestAccounts", []);
      let signer = provider.getSigner()
      await signer.getAddress()
      //let signer = new ethers.Wallet("0x8ddadf5b3738c3cf6201bcbc664dd4d96c3cddaddf924c3dfa73772467a4179f");
      console.log("Address: "+signer.address)
      let lm = new SignHelper(contractAddress, chainId, signer);
       let voucher = await lm.createSignature(id);
      //console.log(voucher.signature);
       return voucher.signature;

    }
    

    async _signingDomain(){
      if(this._domain != null){
        console.log("this.domain not null: "+this._domain);
        return this._domain;
      }
      const chainId = chain_id;
      this._domain = {
        name: SIGNING_DOMAIN_NAME,
        version: SIGNING_DOMAIN_VERSION,
        verifyingContract: this.contractAddress,
        chainId
      }
      console.log("this.domain: "+this._domain);
      return this._domain
    }
    static async getSignFromP(contractAddress, chainId, id){
      // let provider = new ethers.providers.Web3Provider(window.ethereum)

      //  await provider.send("eth_requestAccounts", []);
      // var signer = provider.getSigner()
      // await signer.getAddress()

      let signer = new ethers.Wallet("0x8ddadf5b3738c3cf6201bcbc664dd4d96c3cddaddf924c3dfa73772467a4179f");
      console.log("Signer Address: "+signer.address)
      let lm = new SignHelper(contractAddress, chainId, signer);
       let voucher = await lm.createSignature(id);
      //console.log(voucher.signature);
       return voucher.signature;

    }
  }
  async function show_error_alert(error){
    let temp_error = error.message.toString();
    console.log(temp_error);
    let error_list = [
      "It's not time yet",
      "Sent Amount Wrong",
      "Max Supply Reached",
      "You have already Claimed Free Nft.",
      "Presale have not started yet.",
      "Presale Ended.",
      "You are not Whitelisted.",
      "Sent Amount Not Enough",
      "Max 20 Allowed.",
      "insufficient funds",
      "Exceeding Per Tx Limit",
      "mint at least one token",
      "incorrect ether amount",
      "Presale Ended.",
      "Sale is Paused.",
      "You are not whitelisted.",
      "Invalid Voucher. Try Again.",
      "Max Supply Reached.",
      "Public sale is not started",
      "Needs to send more eth",
      "Public Sale Not Started Yet!",
      "Exceed max adoption amount",
      "Private Sale Not Started Yet!",
      "Exceed max minting amount"
    ]

    for(let i=0;i<error_list.length;i++){
      if(temp_error.includes(error_list[i])){
        // set ("Transcation Failed")
        alert(error_list[i]);
      }
    }
  }


  async function mint_WL_presale_signing(){
    let random_int = Math.floor(Math.random() * 1000000000000);
    if(Web3.givenProvider ){

      const web3 = new Web3(Web3.givenProvider);
      await Web3.givenProvider.enable()
      const contract = new web3.eth.Contract(contract_abi, contract_address);

      const addresses = await web3.eth.getAccounts()
      const address = addresses[0]
      console.log("addresses[0]: "+addresses[0])

      if(check_whitelist(address) == "false"){
        alert("You are not whitelisted.");
        return;
      }

      let total_allowed = check_whitelist(address);

      try{
        // await sign_tx();
        let x = SignHelper.getSignFromP(contract_address, chain_id, random_int);
        let sig = await x.then((result1)=>{
          return result1;
        });

        //console.log("Signatire:  "+sig);
        const estemated_Gas = await contract.methods.mintPresaleWL(mintAmount.toString(), random_int.toString() , sig.toString(), total_allowed ).estimateGas({
          from : address,
          value: "0",
          maxPriorityFeePerGas: null,
          maxFeePerGas: null
        });
        console.log(estemated_Gas)
        await contract.methods.mintPresaleWL(mintAmount.toString(), random_int.toString() , sig.toString(), total_allowed).send({
          from : address,
          value: "0",
          gas: estemated_Gas,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null
        })

        alert("Mint Success")

      }catch(e){
        show_error_alert(e);
      }

      // await contract.methods.tokenByIndex(i).call();
    }else{
      alert("Web3 Not Found. Try refreshing if you have metamask installed.");
    }

  }

  async function handleMint(){

    if(Web3.givenProvider ){

      const web3 = new Web3(Web3.givenProvider);
      await Web3.givenProvider.enable()
      const contract = new web3.eth.Contract(contract_abi, contract_address);

      const addresses = await web3.eth.getAccounts()
      const address = addresses[0]
      console.log("addresses[0]: "+addresses[0])

      if(mintAmount > 5){
        alert("You cannot mint more than 5");
        return;
      }

      // let price = parseInt(newprice) * parseInt(mintValue);
      // price = Math.round(price * 100) / 100;
      //console.log("Price: "+price+ "  .........   "+mintValue);
      //   price =0.006;
      try{
        const estemated_Gas = await contract.methods.mintNFT(mintAmount.toString() ).estimateGas({
          from : address,
          value: "0",
          maxPriorityFeePerGas: null,
          maxFeePerGas: null
        });
        console.log(estemated_Gas)
        await contract.methods.mintNFT(mintAmount.toString()).send({
          from : address,
          value: "0",
          gas: estemated_Gas,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null
        })
        alert("Mint Success")
      }catch(e){
        show_error_alert(e);
      }

      // await contract.methods.tokenByIndex(i).call();
    }else{
      alert("Web3 Not Found. Try refreshing if you have metamask installed.");
    }

  }
  const incAmount = () => {
    if(mintAmount < 100) {
      setAmount(mintAmount + 1);
    }
  }

  const decAmount = () => {
    if(mintAmount > 1) {
      setAmount(mintAmount -1);
    }
  }



  return (
    <FixedSection fluid id="contMint">
      <Container>
        <Row className="justify-content-center">
          <Col md="6" lg="5" xl="4" xs="8" className="text-center">
            <img src={GIFPFP} id="videoDAOVIP" alt="HiFi PFP"/>
          </Col>
          <Col id="colMint" md="6" className='justify-content-center'>
            <Container>
              <Row>
                <Col className="d-flex justify-content-center">
                  <img id="imgControls" onClick={decAmount} src={pngDec} alt="-"/>
                  <div id="divFrame">
                    <input id="txtAmount" readOnly type="text" value={mintAmount}/>
                  </div>
                  <img id="imgControls" onClick={incAmount} src={pngInc} alt="+"/>
                </Col>
              </Row>
              <Row>
                <Col className='d-flex justify-content-center'>
                  {!isWalletConnected && (
                      <img id="btnMint" onClick={connect_wallet} src={pngConnect} alt="connect"/>
                      )}
                  {isWalletConnected && privatesaleStarted && (
                      <img id="btnMint" onClick={mint_WL_presale_signing} src={pngMint} alt="Private Sale Mint"/>
                  )}
                  {isWalletConnected && publicsaleStarted && (
                      <img id="btnMint" onClick={handleMint} src={pngMint} alt="Mint now"/>
                  )}
                  {isWalletConnected && !privatesaleStarted && !publicsaleStarted && (
                    <img id="btnMint" onClick={()=>{alert("Sale not started yet!")}} src={pngMint} alt="connect"/>
                  )}

                </Col>
              </Row>
              <Row className='justify-content-center'>
                <Col className='col-xl-8 col-lg-10 col-md-12 col-sm-10 pt-2'>
                  <span className='info-text'>
                    Hidden Fish DAO PFP Freemint. Snapshot for whitelist taken 22.12.2022. Public mint starts 20.02.2023.
                  </span>

                </Col>
              </Row>

              <SocialLinks />

            </Container>
          </Col>
        </Row>
      </Container>
    </FixedSection>
  );
}

export default App;
