import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';

const FixedSection = ({...props}) => {
   const [windowHeight, setWindowHeight] = useState<number>(0);
   useEffect(() => {
      
      const handleResize = () => {
         setWindowHeight(window.innerHeight);
      }

      handleResize();

      window.addEventListener('resize', handleResize);

      return()=>{
         window.removeEventListener('resize', handleResize);
      };

   }, [windowHeight]);

   return(
      <>
         <Container {...props} style={{'height': windowHeight}}>
         </Container>
      </>
   );
}

export default FixedSection;
