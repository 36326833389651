
//export const speedy_nodes = "https://speedy-nodes-nyc.moralis.io/6f6d56b3c3b81749704789a6/eth/rinkeby";
// export const speedy_nodes = "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
export const speedy_nodes = "https://evm.cronos.org/";
export const chain_id = 25;
export const contract_address = "0x28Fc9DaCa33E0a3Ada666064F54B400E63596c6C";
export const contract_abi = [
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_quantity",
				"type": "uint256"
			}
		],
		"name": "mintNFT",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintedQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_quantity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "id",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "signature",
				"type": "bytes"
			},
			{
				"internalType": "uint256",
				"name": "_max",
				"type": "uint256"
			}
		],
		"name": "mintPresaleWL",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "_wallets",
				"type": "address[]"
			}
		],
		"name": "sendGifts",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_num",
				"type": "uint256"
			}
		],
		"name": "sendGiftsToWallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_supply",
				"type": "uint256"
			}
		],
		"name": "setmaxPrivateSupply",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_supply",
				"type": "uint256"
			}
		],
		"name": "setmaxPublicSupply",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_supply",
				"type": "uint256"
			}
		],
		"name": "setmaxSupply",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "temp",
				"type": "uint256"
			}
		],
		"name": "setMaxTx",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_price",
				"type": "uint256"
			}
		],
		"name": "setPrice",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleWLSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "updateBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_temp",
				"type": "string"
			}
		],
		"name": "updateExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "_extension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "id",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "signature",
				"type": "bytes"
			}
		],
		"name": "check",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "getMintedCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getOwnershipData",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "addr",
						"type": "address"
					},
					{
						"internalType": "uint64",
						"name": "startTimestamp",
						"type": "uint64"
					},
					{
						"internalType": "bool",
						"name": "burned",
						"type": "bool"
					}
				],
				"internalType": "struct ERC721A.TokenOwnership",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxPrivateSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxPublicSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "price",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "privateSaleOpen",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleOpen",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "voucherIds",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]

export const whitelist_addresses = [
    {
        "wl_address": "0xB759BD0dDa29426f375FE314b5501eB8e1974BDF",
        "spots": 5
    },
    {
        "wl_address": "0xb3795fc2fda5543903c60a89b156fe7f7116df80",
        "spots": 32
    },
    {
        "wl_address": "0x22fea1190f6c13b1581d3e0e90eb44c2485648c9",
        "spots": 44
    },
    {
        "wl_address": "0xfddbfa45ebe232e82e622e36dbec8964c2cb0550",
        "spots": 23
    },
    {
        "wl_address": "0xd085fdbc919cc5c1fc807a0c9b90409c387f0ca1",
        "spots": 72
    },
    {
        "wl_address": "0x9a8a7996316d50dd1a40f00974a811813ffaf36c",
        "spots": 38
    },
    {
        "wl_address": "0x447bc82d8c158143fcaab0a6a6dcf608fdb24318",
        "spots": 30
    },
    {
        "wl_address": "0x01f4eed3e0297b0cc0363d0f3db49780f41b8923",
        "spots": 29
    },
    {
        "wl_address": "0x84b864c3b612a7bc9e0afb66e9facbd9e3768ff8",
        "spots": 45
    },
    {
        "wl_address": "0x6d7cc1045bb46e1c697f4d3db88df3e2453e8c5b",
        "spots": 33
    },
    {
        "wl_address": "0xd77df0b7b7d7b45693c21aac464af2ba10433171",
        "spots": 27
    },
    {
        "wl_address": "0xff1b10daf7c7d02620ad398b33f5563d2a43c6ea",
        "spots": 28
    },
    {
        "wl_address": "0x7d19107bda746d89190bcda18f13220a0019112d",
        "spots": 24
    },
    {
        "wl_address": "0x37dcc16047d1a855736435bfca1ece4b463b8082",
        "spots": 24
    },
    {
        "wl_address": "0xede3881ec39b22239d3863a46b0afe895c893bcb",
        "spots": 31
    },
    {
        "wl_address": "0xbb32736429374a891fc7524a0d9e769504e8caa6",
        "spots": 20
    },
    {
        "wl_address": "0xea53e34b378140fc82da60244f50432e5ebd472e",
        "spots": 20
    },
    {
        "wl_address": "0xde00e148d7fc0335e6cb605ef731aff452e997b6",
        "spots": 20
    },
    {
        "wl_address": "0xe310ccc3b0c66ecbfc3ba6cf0ad3581acdb46b31",
        "spots": 20
    },
    {
        "wl_address": "0x0c9bba9e84e0f88f0381187bc67a9c14abd3e9e2",
        "spots": 14
    },
    {
        "wl_address": "0x715137e40f5d088a34258dc5436b27ede9ca2cd6",
        "spots": 22
    },
    {
        "wl_address": "0x59fa2cc8c8657154e63f97f3175ccfed5212c2fe",
        "spots": 20
    },
    {
        "wl_address": "0xc9c276b39389903aad49e33b83625bc0f114a823",
        "spots": 16
    },
    {
        "wl_address": "0x3d45f430818dc3ca130f51e2fdb9d1d3f636fb3c",
        "spots": 16
    },
    {
        "wl_address": "0x30de904e95673bbb03753af05dddf2f06d023a5d",
        "spots": 16
    },
    {
        "wl_address": "0x070182a365c90710865ac4968ba28a420efb198f",
        "spots": 12
    },
    {
        "wl_address": "0x02e71dcea944cdef5d15c29b94158f5e3324376a",
        "spots": 12
    },
    {
        "wl_address": "0xbd6a6c4fe916f269ce57808974663134133e1f26",
        "spots": 10
    },
    {
        "wl_address": "0x25630d608ff94813676e4809f72366c81487716f",
        "spots": 18
    },
    {
        "wl_address": "0x8fb30cba20efdd1e1aeed71dadad51e66832059f",
        "spots": 16
    },
    {
        "wl_address": "0x064d7ad41ec49a7774254ddc3a2fa2e74fc5921c",
        "spots": 14
    },
    {
        "wl_address": "0x988867a5ccad02119ae8db9850bd1380ac876c33",
        "spots": 12
    },
    {
        "wl_address": "0xb2cf87b5dd3212d042867c9e5d165ef4afdef5c8",
        "spots": 12
    },
    {
        "wl_address": "0x574ceac75090869c92fd5315d0e89a0294ca58fc",
        "spots": 10
    },
    {
        "wl_address": "0x2bb187f2750e27ae1ee3f7456738cb600ac70a2b",
        "spots": 8
    },
    {
        "wl_address": "0x0c84cb9d03a5639ba73f08de1586cede48a2e6e6",
        "spots": 6
    },
    {
        "wl_address": "0xeb3d65434c8bacb70e47419b3f9fb892ee652a66",
        "spots": 35
    },
    {
        "wl_address": "0x5eea2e17686e33d88495fa8b8050d088e36d84b8",
        "spots": 15
    },
    {
        "wl_address": "0x7d4b43c81fe0ed4a685298da0bd8a56a2020a855",
        "spots": 13
    },
    {
        "wl_address": "0xe6fcecc17473cf56fa7bb42d0b59e25acad6cf3b",
        "spots": 9
    },
    {
        "wl_address": "0xc7b495bf6c4dca91efbc8a70b2bf6a23844dad17",
        "spots": 7
    },
    {
        "wl_address": "0xe469a7874ae57b2cd00cfd0639396d33c3d060b1",
        "spots": 7
    },
    {
        "wl_address": "0xde7317aa4e970d4d9efe8666e35650a172d98b5d",
        "spots": 7
    },
    {
        "wl_address": "0xc4e3ed995fe71f3a05a803bc9da65ed5bb1e50a1",
        "spots": 7
    },
    {
        "wl_address": "0x7cee7d232dcae23fd673ea798078fde6c5a11c3a",
        "spots": 7
    },
    {
        "wl_address": "0x486ad9d12d4210471a403efcc16ec52c6df11ffb",
        "spots": 7
    },
    {
        "wl_address": "0x8930a23408a551b2c957f06f34342eef38e4837b",
        "spots": 7
    },
    {
        "wl_address": "0x80075c04f21f8c0ae2e7261a443dd4a57c3f07d0",
        "spots": 7
    },
    {
        "wl_address": "0x5fd2ee03d96f53db1d563630749a4730df5e343a",
        "spots": 7
    },
    {
        "wl_address": "0xbf9aaddf41ad117c2a0422f1d9bb556501f59407",
        "spots": 5
    },
    {
        "wl_address": "0x0f2f731526a7ebae3942c2326fb2c78a9fd849b4",
        "spots": 12
    },
    {
        "wl_address": "0x6bc6f6bfc99444e343342fc132769dd3b9985dfe",
        "spots": 10
    },
    {
        "wl_address": "0x40ed863846d078a02b86e5ad242028b27c6d9aed",
        "spots": 10
    },
    {
        "wl_address": "0x9c7de0a482a4c18e353fe615ff9de54beabc7966",
        "spots": 10
    },
    {
        "wl_address": "0x802eba580798dd6ecdcf06d154bd35a020a5e1e6",
        "spots": 10
    },
    {
        "wl_address": "0xc875e7455a9f3c816efdd3e85f9f374db2d99ac6",
        "spots": 10
    },
    {
        "wl_address": "0xe2b5f46830816e1c2cf196f5c45aa23402cec04e",
        "spots": 8
    },
    {
        "wl_address": "0x48caae1a49486f6e01a988e99fca1a6fd812475b",
        "spots": 8
    },
    {
        "wl_address": "0x1f346e6d1559d4904c3fd824ac7aaa12f4046e6e",
        "spots": 8
    },
    {
        "wl_address": "0x9b5da33e607ce9025c5776c816b341f138e5f5e0",
        "spots": 8
    },
    {
        "wl_address": "0x0fe3940242626d87a5702dc11c403162f0958054",
        "spots": 8
    },
    {
        "wl_address": "0x95d38b7fa4e7073df3c7ca14fc8f936e233aa451",
        "spots": 6
    },
    {
        "wl_address": "0xfb51c93ea2b0cfb84f2986c4dcb3fb1d89883498",
        "spots": 4
    },
    {
        "wl_address": "0x9c8baefa5399a6cdcef8454d806dc18581ef84dc",
        "spots": 4
    },
    {
        "wl_address": "0xca101579ce859707fba96606d265a0c0f689ef44",
        "spots": 4
    },
    {
        "wl_address": "0xfbb1919ede697fd138a905974a2e8154a45152be",
        "spots": 13
    },
    {
        "wl_address": "0x15e71f369fa53e4d45db9a7be0e6786be47d35a9",
        "spots": 9
    },
    {
        "wl_address": "0x5bb4aa74a403251e555ed5d7bac63727891a9274",
        "spots": 7
    },
    {
        "wl_address": "0x93fae66033cf8d4aacf4be1255ed5e10d3bcc68f",
        "spots": 7
    },
    {
        "wl_address": "0x799c7148257bfb57100f98bedde5286bd561acb1",
        "spots": 5
    },
    {
        "wl_address": "0x9eccffd68e05ed0aa5353f5b5bbfbea13a744106",
        "spots": 5
    },
    {
        "wl_address": "0xb8932caf85c8d5ef925346efe213b5d486f004b2",
        "spots": 5
    },
    {
        "wl_address": "0x89d8f750f749a76444976d8f8851ddbb0ead32eb",
        "spots": 5
    },
    {
        "wl_address": "0x6621d1eaa7f8e63626fcd1f1feaa1dfb00cfa177",
        "spots": 5
    },
    {
        "wl_address": "0x501a33e4334461862ac6d9434a68e468c66b70c5",
        "spots": 3
    },
    {
        "wl_address": "0xca320b0019365e89e313f78770964fbe0ac983c8",
        "spots": 3
    },
    {
        "wl_address": "0xc71d6ab52499f47f349eec17aa780e3f790cd06c",
        "spots": 28
    },
    {
        "wl_address": "0x8db0e097dd19748cf80da88cd0f87ea82547bc36",
        "spots": 26
    },
    {
        "wl_address": "0x6e90ed0ec725b04e8452af4c18b92d00a5a85236",
        "spots": 12
    },
    {
        "wl_address": "0x1bbc3c2e674c26264c08bbd8a7911c404d4e3afb",
        "spots": 10
    },
    {
        "wl_address": "0x63c56771c65e1eea3b4a9e729ef1034c2d16e838",
        "spots": 8
    },
    {
        "wl_address": "0x08367f9c414a4196474d16303d367b7a959ffbec",
        "spots": 6
    },
    {
        "wl_address": "0x3d05f260fd6a808546175d613f449daa9520458c",
        "spots": 6
    },
    {
        "wl_address": "0x898f458f9cd73202ebf53ccdd6c48a7657e866a1",
        "spots": 6
    },
    {
        "wl_address": "0x89ed68faa256b7f49842bbc106b008256f5d54fd",
        "spots": 6
    },
    {
        "wl_address": "0x8efe9e54d5328226724cb7fb8d9a6a5d6519cf7d",
        "spots": 6
    },
    {
        "wl_address": "0x0514f65e09228c25fa76f6d9aeb7b9f2880bac69",
        "spots": 4
    },
    {
        "wl_address": "0x3f83f93ee57feeb0ff225b921141d71b286ee749",
        "spots": 4
    },
    {
        "wl_address": "0xe13a370fece549f8a38ba243815873241ba6f02e",
        "spots": 4
    },
    {
        "wl_address": "0xb4b4288cf43bb44923d1eae665a28e4e36e5fcae",
        "spots": 4
    },
    {
        "wl_address": "0x3925e14405ec42bc45d2b4ac272f650158391ffa",
        "spots": 4
    },
    {
        "wl_address": "0xc5798691cdc9e426009e71b36c49c39b708fc39f",
        "spots": 4
    },
    {
        "wl_address": "0xc368652d2aeac810fd8b6178cbb9b9107b6dd261",
        "spots": 4
    },
    {
        "wl_address": "0xe0202574956966365b4fb32f661b403c9ae019ab",
        "spots": 4
    },
    {
        "wl_address": "0xe436c5c45d5b8b9dedf37a59d9be8ae7864b26cb",
        "spots": 4
    },
    {
        "wl_address": "0xbdaa80fe18fab13f9596a0b5b1832b205b666382",
        "spots": 4
    },
    {
        "wl_address": "0x6e4438e8fde40a895aa9aace38247ed89405ad63",
        "spots": 4
    },
    {
        "wl_address": "0x72cdc2af28c4fe9e1f61e7f217ad4ac4b491192f",
        "spots": 4
    },
    {
        "wl_address": "0xee50f09f5426ce288a17cd228187108d8906c6bc",
        "spots": 4
    },
    {
        "wl_address": "0x5558635e9719f68e9a380c6d244887a15af0a9be",
        "spots": 4
    },
    {
        "wl_address": "0xd0e1948e382ed3274c27a8a84c83d5f373bfccd3",
        "spots": 4
    },
    {
        "wl_address": "0xfb6976681ddb41509a814aade1e1c483c31285a9",
        "spots": 4
    },
    {
        "wl_address": "0xfcb56456955b48890083369630bf845d37720fd9",
        "spots": 4
    },
    {
        "wl_address": "0xfdf1bff1850f05641075588e380622d25774a7ec",
        "spots": 4
    },
    {
        "wl_address": "0xff29349da41847c4ce945fc37144d470e95ef028",
        "spots": 4
    },
    {
        "wl_address": "0x481a4e5d0368abc9978cd3e1fc8a40f404771a8c",
        "spots": 4
    },
    {
        "wl_address": "0x37a25c1ff2d64252ee7bc540eee808e7c8490c2f",
        "spots": 4
    },
    {
        "wl_address": "0x232b41cc1389063bcd05dbce2ab9a9571f423002",
        "spots": 4
    },
    {
        "wl_address": "0x0ced89c3db457e321131644f3782a0f2eb126a47",
        "spots": 4
    },
    {
        "wl_address": "0xb0e6a5aa1e4401736fe8207a3270bf92d6658f07",
        "spots": 4
    },
    {
        "wl_address": "0x9b94f48372f5ed14f860b86f606ffb61d908e4dc",
        "spots": 4
    },
    {
        "wl_address": "0x8becd171559cbc23a72faf54dfe95b5d58c5ca27",
        "spots": 4
    },
    {
        "wl_address": "0x82d9743c912b92ce78704b5227c22a3f4b3cfcd5",
        "spots": 4
    },
    {
        "wl_address": "0x961b6c741631ba87ce2d51d6f0ddb7c633209a00",
        "spots": 4
    },
    {
        "wl_address": "0x8ec0b455036b366ea380fa4b6fee1d8de9da6646",
        "spots": 4
    },
    {
        "wl_address": "0x1f895d681b1f1dd522ea8840a224d16ad98a4fc4",
        "spots": 4
    },
    {
        "wl_address": "0xb2a629375be838314844a0eebac9486a91f04f9a",
        "spots": 4
    },
    {
        "wl_address": "0xae01556f3f41781c9da49334fb132fd6fc5f6496",
        "spots": 4
    },
    {
        "wl_address": "0x8b5b455c28fca823a41bfaea13b1e3c87a505721",
        "spots": 4
    },
    {
        "wl_address": "0x2fb8b07d303839656262f939a7878342c0cf20d8",
        "spots": 4
    },
    {
        "wl_address": "0x6ebc430c5610bd5c9ab05bdc210dd14e9bce6b93",
        "spots": 2
    },
    {
        "wl_address": "0x6c0d18acd46ddf15f2e7f16cfa56b9f55abf828d",
        "spots": 2
    },
    {
        "wl_address": "0xb63bb605d86bf0d1dcd17177b06643ee4f5fe373",
        "spots": 2
    },
    {
        "wl_address": "0xae9a264c9235a1e3a825963299643cd305c0a26e",
        "spots": 2
    },
    {
        "wl_address": "0x664c08a63c674e8cd53f8bc5d572dfa85fc14546",
        "spots": 2
    },
    {
        "wl_address": "0xff773c82ce3f199bcab3fb90d1959cba50d73dfe",
        "spots": 2
    },
    {
        "wl_address": "0x04a8e006ae6c34b04e191af92627d5ed10f6fec3",
        "spots": 2
    },
    {
        "wl_address": "0x3a3569a824e1602fc2180a716dbec2e1275108c4",
        "spots": 2
    },
    {
        "wl_address": "0x07227ea8bf2b88e8bdb2e4b217b7803c719c56a7",
        "spots": 2
    },
    {
        "wl_address": "0x72520dabe6d2b279472fc3483607dc071a4735bc",
        "spots": 2
    },
    {
        "wl_address": "0x6c12f884501bd892782f921d2aa0400bae3c92ab",
        "spots": 55
    },
    {
        "wl_address": "0xd156f87ea9f25b7820582b460553ee621eddc258",
        "spots": 43
    },
    {
        "wl_address": "0xd05703407217fd35c447f88380826a92ea2d9763",
        "spots": 13
    },
    {
        "wl_address": "0x806f122a3c70811488e2d4c21e420deda74bc422",
        "spots": 11
    },
    {
        "wl_address": "0x514d30c2526598a634c5686e8a235fc4f2aa850f",
        "spots": 9
    },
    {
        "wl_address": "0xf94b3d00efca290968a57ebe82ae433e55ef5faa",
        "spots": 7
    },
    {
        "wl_address": "0xbb02ad71112b1e59191dddab58d122905029d5bc",
        "spots": 7
    },
    {
        "wl_address": "0xe07355a6aa9b91a29ee66ed939deceecd7c238e3",
        "spots": 7
    },
    {
        "wl_address": "0xee506ec4b9636dd94e1f4a3952aa7191effed361",
        "spots": 5
    },
    {
        "wl_address": "0x3ed94df49728cfb4cbf80ff6616def81b1379f7f",
        "spots": 5
    },
    {
        "wl_address": "0xb1e76c5061075c8a59c59724e76f957437d7e02b",
        "spots": 5
    },
    {
        "wl_address": "0x9a9be94016d9db344f234274c4308d15a298ed49",
        "spots": 5
    },
    {
        "wl_address": "0x06eeaff949280783c1ce31970cf4007181fe9a9c",
        "spots": 3
    },
    {
        "wl_address": "0x89db83aa9d69dbb555e9c884d66fc4486c819992",
        "spots": 3
    },
    {
        "wl_address": "0xfb8e7d50a5057d68dc0469f058a6a364bd7b4ca6",
        "spots": 3
    },
    {
        "wl_address": "0x7021c65baee488ca957de4499e5e742e56417ecb",
        "spots": 3
    },
    {
        "wl_address": "0xcba243c836764892123ddf8996bea38837c8ac6b",
        "spots": 3
    },
    {
        "wl_address": "0x2eb71a9d6df4409d310141dcf599811326d518c1",
        "spots": 3
    },
    {
        "wl_address": "0x4a010b211ec113f96d236fef8da7dd7a85d79a25",
        "spots": 3
    },
    {
        "wl_address": "0xff3da1678b275286d2cf798c2e67c09f7dbe2520",
        "spots": 3
    },
    {
        "wl_address": "0xf678cede1cb4ac9bffd45ce9f76f25dd2550f716",
        "spots": 3
    },
    {
        "wl_address": "0x566a1ff1dc54cad033184d25e744c44fa9960e1e",
        "spots": 3
    },
    {
        "wl_address": "0x6a6d058d91ddc2117b1244e63b43f82a87206e74",
        "spots": 3
    },
    {
        "wl_address": "0xdc56371676a4fa8346df05ce286a81ab372ff1a0",
        "spots": 3
    },
    {
        "wl_address": "0x8fd7347c9d0bb407a7fe48216ac60cec7dfb8670",
        "spots": 3
    },
    {
        "wl_address": "0x1f69c0e0a818297f38793059568fcb20b20b0e36",
        "spots": 3
    },
    {
        "wl_address": "0x62b469cf265ac7902f53ba86baea144d3d0fbc3e",
        "spots": 3
    },
    {
        "wl_address": "0xd57124a28cf6006614e9cce44e09830e982655ed",
        "spots": 3
    },
    {
        "wl_address": "0x610714b96e00dc2bac4054ee35c8d24f25da3b7a",
        "spots": 3
    },
    {
        "wl_address": "0xe568afb492816e8c790ec74420d7bc008a5bd85a",
        "spots": 3
    },
    {
        "wl_address": "0xd1c5c0a35992c21d7fbf9571efc1e60d74497b8a",
        "spots": 3
    },
    {
        "wl_address": "0x24a844b7a9a514002d14442cbec50d383b3518e0",
        "spots": 3
    },
    {
        "wl_address": "0xf43bc25fd1c95d90671bffe1c78407946b13f224",
        "spots": 3
    },
    {
        "wl_address": "0xff69f8acf0d7a04529627fc09c22d54d5c323176",
        "spots": 3
    },
    {
        "wl_address": "0x70ccc9d0205c83a17e94c0e8c473968ef0d74c84",
        "spots": 3
    },
    {
        "wl_address": "0x912d8284284286d2c21dd2f069729c49bcf0c602",
        "spots": 3
    },
    {
        "wl_address": "0x7708c60cc22f13bc73a4cb748bc6802d8dcef9de",
        "spots": 3
    },
    {
        "wl_address": "0xbf6333158f92e60f652e40dcb7bb91960609d03e",
        "spots": 3
    },
    {
        "wl_address": "0xbd8a05e2d44a7544640d891a214fdb32f28c5675",
        "spots": 3
    },
    {
        "wl_address": "0x8447d995535bdf24c4401bd05c0de120232fdc62",
        "spots": 3
    },
    {
        "wl_address": "0x3d52a07cc75926e10c113d2842eb3afb44a3d843",
        "spots": 3
    },
    {
        "wl_address": "0xb6ea6d126a2468786f11e1e09c9d283c03788753",
        "spots": 3
    },
    {
        "wl_address": "0x076cc6533433d15711fa66e3ac00ef4ae347c925",
        "spots": 3
    },
    {
        "wl_address": "0x4843ca916c45ea85ca0d2adacd6b4edf5d5ece6e",
        "spots": 3
    },
    {
        "wl_address": "0xa3613282283f9464e4ddb5ee356c40223c7885fb",
        "spots": 3
    },
    {
        "wl_address": "0xd3a6227ef09267f7534ed0992a87f23620896485",
        "spots": 1
    },
    {
        "wl_address": "0xc51128485f62ca6d485214fbfdab6c39bae7f0c5",
        "spots": 1
    },
    {
        "wl_address": "0x9dd43969fe08cf5e4558dae6baed08a28eef8a9c",
        "spots": 1
    },
    {
        "wl_address": "0x888dd20d2f8b740bea2827d7ca05954076df1965",
        "spots": 1
    },
    {
        "wl_address": "0xb79b7c3e2df901795160bee3b28307f1e79737de",
        "spots": 1
    },
    {
        "wl_address": "0xadc4719f8a5c73811497d0cc5f93e1878f48498e",
        "spots": 1
    },
    {
        "wl_address": "0xab88533c1d07c777057ea130c9a167d674db92fc",
        "spots": 1
    },
    {
        "wl_address": "0xaa590b672b3ab6f293afc18f005f7a08e1ce8b32",
        "spots": 1
    },
    {
        "wl_address": "0xa9c542d2070e3965a347bc43460f3027b8bf3c32",
        "spots": 1
    },
    {
        "wl_address": "0xa95dd34cbc0db53969ca416570bb7f6f0e778b7a",
        "spots": 1
    },
    {
        "wl_address": "0xa3ca67e7c3b1e3ddc6f12653755485a75cff29f8",
        "spots": 1
    },
    {
        "wl_address": "0xa3789fba8673aa9015a0005d43d9d38304f3aacd",
        "spots": 1
    },
    {
        "wl_address": "0xa2d767629ef6aafb33a2c072ef067098a0e8e639",
        "spots": 1
    },
    {
        "wl_address": "0x9b958d021897c242d3485ed86f61c2764fccc1a0",
        "spots": 1
    },
    {
        "wl_address": "0x97bafcf8a372c6863fde128fdcf695fa5b1e3930",
        "spots": 1
    },
    {
        "wl_address": "0x93384b8b4c03f827f18b5e31510401bb31dd1efa",
        "spots": 1
    },
    {
        "wl_address": "0x91a6aef79741c22b5436920db5bea0318fc6c91b",
        "spots": 1
    },
    {
        "wl_address": "0x89ca53eb2ab52418be60556c8052d3542b1f5cfb",
        "spots": 1
    },
    {
        "wl_address": "0x8871bbe99c1aace0d3fe1752b032e3b6057ac5a2",
        "spots": 1
    },
    {
        "wl_address": "0xb83099e161cafa8372587f472f508bc2ea63f48e",
        "spots": 1
    },
    {
        "wl_address": "0x87234c10b750a3325213aaf166101ef86985a080",
        "spots": 1
    },
    {
        "wl_address": "0x852bf0f924d390e782085321a7d6a6da6fc7e7f4",
        "spots": 1
    },
    {
        "wl_address": "0x83e00709d6bcc869c541c41d3110de32d2a07fdd",
        "spots": 1
    },
    {
        "wl_address": "0x7a95d923898cd8254d0b68467846ef7b61095900",
        "spots": 1
    },
    {
        "wl_address": "0x79ff02c3368f0f90892408caff304bcd2c90b7e0",
        "spots": 1
    },
    {
        "wl_address": "0x79eb78bc5ead3902d3f4d19b3c79b5fb75028346",
        "spots": 1
    },
    {
        "wl_address": "0x7775def1f4c34890947c856b1f368545d7d67092",
        "spots": 1
    },
    {
        "wl_address": "0x6f60262923263580a089bacf02197760412d974c",
        "spots": 1
    },
    {
        "wl_address": "0x6cd18cb2272a97e1e520a526be7063028505c654",
        "spots": 1
    },
    {
        "wl_address": "0x69cedc5b4abb1110fdc77d028a9c30d8f3afe821",
        "spots": 1
    },
    {
        "wl_address": "0x698689903011077f374333c8e7d4a8e6c293c410",
        "spots": 1
    },
    {
        "wl_address": "0x693a10158354b0b75f2f4af84f6d5182faa669ac",
        "spots": 1
    },
    {
        "wl_address": "0x63612bff0c6b7d2d305e218caacd7a6cba5c1c07",
        "spots": 1
    },
    {
        "wl_address": "0x634a5dc4cda63ea8d0699d9387cdcfffc6408b84",
        "spots": 1
    },
    {
        "wl_address": "0xb7d53c050b3b03b316ac5226fd170afa774dcce7",
        "spots": 1
    },
    {
        "wl_address": "0xb9259b349467f355988e436bdf00e0756e41772b",
        "spots": 1
    },
    {
        "wl_address": "0xd85e8e6ff5089287f31b5135b6767a76dd1fbd98",
        "spots": 1
    },
    {
        "wl_address": "0xfb5e9532bb176898de8bb68321bccfead7500d29",
        "spots": 1
    },
    {
        "wl_address": "0xfa50cde7debc6d7e1a75a49a5d854f1f77fd7f01",
        "spots": 1
    },
    {
        "wl_address": "0xfa39d6662ba1f6ccea45e67c9b18f015e732ccc4",
        "spots": 1
    },
    {
        "wl_address": "0xfa35c6392f308a79adef1bf335ff9056fce917c0",
        "spots": 1
    },
    {
        "wl_address": "0xf565911e6bdafa550fac6c210a0d09a64049c7f1",
        "spots": 1
    },
    {
        "wl_address": "0xf2914e58863bbaace88b84b03b190d367beb6030",
        "spots": 1
    },
    {
        "wl_address": "0xea4d30600d0364987a5ccf8b6ae01af4c9c047f7",
        "spots": 1
    },
    {
        "wl_address": "0xe7e5058657652b64b930e89103b4c8c78bed922c",
        "spots": 1
    },
    {
        "wl_address": "0xe7833204abd7e9cdde4fe1b6bc5ca71fbdfa8b22",
        "spots": 1
    },
    {
        "wl_address": "0xdf42607196b9c870e72c85a9477f623824d7cd55",
        "spots": 1
    },
    {
        "wl_address": "0xdd749f5c3d96b6af16e1dc3a74f4aafbeb8420c8",
        "spots": 1
    },
    {
        "wl_address": "0xdd69a2cc9c4b06a4f2fb28e3b1486d177dd84f63",
        "spots": 1
    },
    {
        "wl_address": "0xdd54783c738016cb6940eba314aee1f76bebc425",
        "spots": 1
    },
    {
        "wl_address": "0xd8d3300165c9a3046f85f01be35a45439ce299f4",
        "spots": 1
    },
    {
        "wl_address": "0xd7793601e62da0716d0076a81622957a352892e8",
        "spots": 1
    },
    {
        "wl_address": "0xbc4eb4236a9c02ee56ed778b3de0fb8d6a09ca5e",
        "spots": 1
    },
    {
        "wl_address": "0xd5cd731d196226cd4f80fa91dab28f5d53ab8264",
        "spots": 1
    },
    {
        "wl_address": "0xd49a1d51c7a871ddd65b338d508613da60041e93",
        "spots": 1
    },
    {
        "wl_address": "0xca9f472dfae323d8ad676f7f19ba5ba61cfeb04a",
        "spots": 1
    },
    {
        "wl_address": "0xc9f4ceff0fef205256cf9921d128f8a6985a01b9",
        "spots": 1
    },
    {
        "wl_address": "0xc95728fa60bd8fdebbc1258a5a7aa7780a402312",
        "spots": 1
    },
    {
        "wl_address": "0xc88f9cb5c4809f3140cde88d2de90f1ad360ecf5",
        "spots": 1
    },
    {
        "wl_address": "0xc778523070ca95d87f4385fcd088df32e643a3d3",
        "spots": 1
    },
    {
        "wl_address": "0xc74c49057132209015ae4ab0a7117b9f694f4b88",
        "spots": 1
    },
    {
        "wl_address": "0xc6e778b3d0b43371e20bd878c764a80df65d2b82",
        "spots": 1
    },
    {
        "wl_address": "0xc414d109f1e8d01228ac7c14d739133b27bf8a6e",
        "spots": 1
    },
    {
        "wl_address": "0xc2ec9663f6da09c089643fe9b3c6c4d886e07d30",
        "spots": 1
    },
    {
        "wl_address": "0xc291a8a97ca8f025eabea7f8e8820a3c2b908972",
        "spots": 1
    },
    {
        "wl_address": "0xbdc1c8b187b2e3c214d272f4133fd66e76682653",
        "spots": 1
    },
    {
        "wl_address": "0xbd7ebc405f639f6588f8d28fb55c698fcaa9c849",
        "spots": 1
    },
    {
        "wl_address": "0x5ef3de8c6524930c02f502ee70bf8961f6d8fce4",
        "spots": 1
    },
    {
        "wl_address": "0x5b129c4052e2224a1664e676bfcc7ee561f22258",
        "spots": 1
    },
    {
        "wl_address": "0x51e20555219ab8d6e0ae34be32d2b0dc08abd078",
        "spots": 1
    },
    {
        "wl_address": "0xfbf23f877c2dd8ef7847bf4427a2f87d3fa651fc",
        "spots": 1
    },
    {
        "wl_address": "0xfbc2366d12b9efc14faf25f6f31dceb326504e1c",
        "spots": 1
    },
    {
        "wl_address": "0x454cfaa623a629cc0b4017aeb85d54c42e91479d",
        "spots": 1
    },
    {
        "wl_address": "0xe21061c6ed1fe0004bc5ad16969c96907f4ef53c",
        "spots": 1
    },
    {
        "wl_address": "0x00e9677fcab0f45adc93572ded98118b3601438c",
        "spots": 1
    },
    {
        "wl_address": "0x02bbf6112a2a73ed577e0eaee5daca523ee4149d",
        "spots": 1
    },
    {
        "wl_address": "0x03581c8013a5fb690de45e8d941531a7c6d40edf",
        "spots": 1
    },
    {
        "wl_address": "0x439a774f487008b13b481b59bc94318f9a987615",
        "spots": 1
    },
    {
        "wl_address": "0x4331a800cb0b8a6c8838458b692d263bf2c62271",
        "spots": 1
    },
    {
        "wl_address": "0x41d1b72b07de5270563d70feb9106ee849452a91",
        "spots": 1
    },
    {
        "wl_address": "0x3eb27610a2481d0ffa54c7483b6587cd14f9bacc",
        "spots": 1
    },
    {
        "wl_address": "0x3a2b576ef1884f10c4b18c06d55e7c07fde4ec12",
        "spots": 1
    },
    {
        "wl_address": "0x3618909ed0c6fe1e8c240b4929cec3e9e5fe9de5",
        "spots": 1
    },
    {
        "wl_address": "0x35e07ff96ffaa5e439a2da7bb90e95ac2add0eb9",
        "spots": 1
    },
    {
        "wl_address": "0x351f2ba1147f135e334b1f758bbfec60a55da885",
        "spots": 1
    },
    {
        "wl_address": "0x32fbc5ac04b96f69d7cd175f0272211165c9843a",
        "spots": 1
    },
    {
        "wl_address": "0x325340a3623684b510378628d2650822565ab4cc",
        "spots": 1
    },
    {
        "wl_address": "0x31a65f5d559fc3f1bea7798819640fe208d139b7",
        "spots": 1
    },
    {
        "wl_address": "0x30826300b1b188a1653b3a31c9628e3e155c0b93",
        "spots": 1
    },
    {
        "wl_address": "0x2d8714104b2fe4af82495712f4cf79da44589454",
        "spots": 1
    },
    {
        "wl_address": "0x2c940b4249cf11ff15f6f5bf59016e2938655c34",
        "spots": 1
    },
    {
        "wl_address": "0x2ba671e2a0266882b96aeac43d6485c9a1ec1ba0",
        "spots": 1
    },
    {
        "wl_address": "0x29f185eb459c4138c8c6663fce10f9513ccdcac3",
        "spots": 1
    },
    {
        "wl_address": "0x255bd8a226c9d5fffc99e8f82f900f982ec5bd7e",
        "spots": 1
    },
    {
        "wl_address": "0x229d39ecfade62d49a5535e8e7157a805f4e75c3",
        "spots": 1
    },
    {
        "wl_address": "0x1fa5283d5a05d09b3502108e8f9a4a18805a163e",
        "spots": 1
    },
    {
        "wl_address": "0x1cd555da28ee8f89e91387cbc69271a7a7ea6689",
        "spots": 1
    },
    {
        "wl_address": "0x1b52b02318ef057e75f9db87f14679a407b1f619",
        "spots": 1
    },
    {
        "wl_address": "0x173466f32990c6de291eaafd190da63f6dd223a2",
        "spots": 1
    },
    {
        "wl_address": "0x1695c8177628025da48ca76f0fd6cb38e4a014c9",
        "spots": 1
    },
    {
        "wl_address": "0x1359135b1c9eb7393f75271e9a2b72fc0d055b2e",
        "spots": 1
    },
    {
        "wl_address": "0x0f231b4b9f8b12d29b442735e28403af478faaf7",
        "spots": 1
    },
    {
        "wl_address": "0x0d51bf4a15471ef024c51b6b7f7c7b1a957f2dab",
        "spots": 1
    },
    {
        "wl_address": "0x0cc9c228b4f5b901ce0b6fa9b004c28dfc4b0960",
        "spots": 1
    },
    {
        "wl_address": "0x0909c42a6c7f1370f880ea5e30a2b98a507a3833",
        "spots": 1
    },
    {
        "wl_address": "0x051f0640c0ad96a7214bda0690805c76afe8b466",
        "spots": 1
    },
    {
        "wl_address": "0x7ac208c48227de1372faacd26ffee9907012bec8",
        "spots": 1
    },
    {
        "wl_address": "0x738757107b525421600486dfae847a931f94ba0c",
        "spots": 1
    },
    {
        "wl_address": "0x22f8a370cb3fec41acf3c22ff78e000b0a27b741",
        "spots": 1
    },
    {
        "wl_address": "0x3722cb2dc7a1d6a778a3f476e162c30f00d6aedf",
        "spots": 1
    },
    {
        "wl_address": "0x14bbc8abfab473babb20526024f12ce819547848",
        "spots": 1
    },
    {
        "wl_address": "0x3b744b7205d030a1bdcb05ff1d778a885b475d4f",
        "spots": 1
    },
    {
        "wl_address": "0x8c119fd12d9095cc6e98bb0d1e7aa4977ee738b7",
        "spots": 1
    },
    {
        "wl_address": "0x8dd37bc0ef6ffa3946ef3b89a693bbb67fe5a289",
        "spots": 1
    },
    {
        "wl_address": "0xd8b6fe7d1903e8003df191bbf5aad191faea1c15",
        "spots": 76
    },
    {
        "wl_address": "0x2c379c16aab12d51b5539a1ca706deb8f964b8f4",
        "spots": 70
    },
    {
        "wl_address": "0x362d83ab1144b0bdac5715a65f90d4c653d6ff74",
        "spots": 30
    },
    {
        "wl_address": "0x01220e9f944489dfa3ca81f324926ae6bb97aedc",
        "spots": 30
    },
    {
        "wl_address": "0x5496f5795f8e8fd1eae02d25cbb56ddf6302a664",
        "spots": 28
    },
    {
        "wl_address": "0xcd200478c63d34970f036e496e833c7969c676a2",
        "spots": 28
    },
    {
        "wl_address": "0x058e2c9e6c0c085901b321eb04ebaf059efcc394",
        "spots": 20
    },
    {
        "wl_address": "0x7890517fd57dc7de2580a5e0c4f44f13edf50a3e",
        "spots": 20
    },
    {
        "wl_address": "0x1e0ba6a34be56fc3429cd733ff182881ff451f68",
        "spots": 20
    },
    {
        "wl_address": "0x9d80920a2b0c75da7827099d1ee0faac117b3375",
        "spots": 20
    },
    {
        "wl_address": "0xea977d5b838de23ecd7e4c659e34f4bb95f46cb1",
        "spots": 20
    },
    {
        "wl_address": "0xc08a7a1c75e8136a2518ae19e0ab7e2338b2b990",
        "spots": 16
    },
    {
        "wl_address": "0x3baf1e9937cfd0d66c1ed1409e36db79be82a56e",
        "spots": 14
    },
    {
        "wl_address": "0xceaac87781e5c9afb7cdbf0ae7a65666fb48c835",
        "spots": 12
    },
    {
        "wl_address": "0xcc7ac84ef5c3764e24c7312cac3c77e5fc0c3517",
        "spots": 12
    },
    {
        "wl_address": "0x8ec697e72b245c7ef73c22e2506a314c665b23c7",
        "spots": 12
    },
    {
        "wl_address": "0xf93e6c16ea54851e3d900caf36fa0b5c40a6b0f3",
        "spots": 10
    },
    {
        "wl_address": "0x59547bf4f7803a4d14ef1f28fba7d464e884dc9c",
        "spots": 10
    },
    {
        "wl_address": "0x23e274c8e8edc4acb1a6322f47bb3508026a40d1",
        "spots": 10
    },
    {
        "wl_address": "0x87da7598f03512f1fe1047fa26b5b7214cbf606c",
        "spots": 10
    },
    {
        "wl_address": "0xca83339ef338fd7fef973ea93241a2494f5ad1e4",
        "spots": 10
    },
    {
        "wl_address": "0xf50d262a10f4904c2620b0c11e9a5cd5708c9179",
        "spots": 10
    },
    {
        "wl_address": "0x72c1985e57b0b4f6b1a660bfd989c36026786afa",
        "spots": 8
    },
    {
        "wl_address": "0x778d173df9554c586a405949f25cb5be97a216f4",
        "spots": 8
    },
    {
        "wl_address": "0x6846cc45a6450ba8d3f565ae3145998aef10a69e",
        "spots": 8
    },
    {
        "wl_address": "0x5cbd7d827ca248b2dc17f334c93cfcedeb072795",
        "spots": 8
    },
    {
        "wl_address": "0x00568d9a9d888ac9d3f4e2c151d0689bcaf7e7fc",
        "spots": 8
    },
    {
        "wl_address": "0xd41252a9b1809ac0032d9b994452f8a67a749466",
        "spots": 8
    },
    {
        "wl_address": "0x64977acf5dec2754d59f7fa7ae96ce564d969b85",
        "spots": 8
    },
    {
        "wl_address": "0xd1495ac60722ecb519f1d27e357ccc36c80c5d49",
        "spots": 8
    },
    {
        "wl_address": "0xd4eb09f457495aa153bf31994af17b245c507c4b",
        "spots": 8
    },
    {
        "wl_address": "0x4123a0eee564415cb29abe9cf40b038656dfaa39",
        "spots": 6
    },
    {
        "wl_address": "0x79437739c9b27732aa8ea0297f54761aa84915f1",
        "spots": 6
    },
    {
        "wl_address": "0xd5c28d99e7a7078734c2e8d246366f0779000b22",
        "spots": 6
    },
    {
        "wl_address": "0xef6932c8ee8e96dba0e74f03a4a6f4f7334b86b3",
        "spots": 6
    },
    {
        "wl_address": "0xcee3b632e787c119dd243c7be4501d89335fcfaf",
        "spots": 6
    },
    {
        "wl_address": "0x0a322c9145274525db0150cf68659cb74357a21f",
        "spots": 6
    },
    {
        "wl_address": "0xe973a3b255479c11f40154b3bd63a1e6dbdb7522",
        "spots": 6
    },
    {
        "wl_address": "0x4fd95c6fa765e64ec9313e465f4d2b88cbf8deaa",
        "spots": 6
    },
    {
        "wl_address": "0xe66529818d9240de14ce246cb4ca46c9c4e81039",
        "spots": 6
    },
    {
        "wl_address": "0xcb79ac8d626cb95cba852665b3152174e846abd7",
        "spots": 6
    },
    {
        "wl_address": "0x2365c4adce759a9399568fe9402668c3de5cb22d",
        "spots": 6
    },
    {
        "wl_address": "0x0db3c7290511a98ce7c086a0b129d6c307cb33f2",
        "spots": 6
    },
    {
        "wl_address": "0xaf22ac9d06e5f3754da1eb6695de379ede7b13fa",
        "spots": 6
    },
    {
        "wl_address": "0xa2b2b3d7f57126dc6c86c6707dfc7eb1b9c4c3dd",
        "spots": 6
    },
    {
        "wl_address": "0xa04c9db398058cad0de73dcb282cf97db51a2c6c",
        "spots": 4
    },
    {
        "wl_address": "0x5290075a7b70a859ef45b1913c9f2357280e20e0",
        "spots": 4
    },
    {
        "wl_address": "0xb3053b9c57cb5b0e5d94389524ec6b89fa0344e8",
        "spots": 4
    },
    {
        "wl_address": "0xcc8214ef2a3c7f65f8e518dbec4b1e2ee58906b1",
        "spots": 4
    },
    {
        "wl_address": "0x58f4a38f80192e4739b10508e3d225b830a21663",
        "spots": 4
    },
    {
        "wl_address": "0x11f451e9577701b0c769dbb6293b74fd170025a0",
        "spots": 4
    },
    {
        "wl_address": "0xd4f8d1a1fea204afbc85840b775c421ca2651fdd",
        "spots": 4
    },
    {
        "wl_address": "0xe95410515f2eb9d9a5d18eaa291785abc6eb5337",
        "spots": 4
    },
    {
        "wl_address": "0x6d698f57ff2f6c006abacdfe189db50bb3168cbb",
        "spots": 4
    },
    {
        "wl_address": "0x27d2f375c9753f10334f0e5a981d5fcc827f1f9e",
        "spots": 4
    },
    {
        "wl_address": "0xdb0e36d1bfbede21f1a3423898d4c43cbf9802bf",
        "spots": 4
    },
    {
        "wl_address": "0x83a1a09ffd4cd72bc7d7d429234271bd40542350",
        "spots": 4
    },
    {
        "wl_address": "0x7c4e2118e3bcae8631920fa53e31c86f5ac9c0e1",
        "spots": 4
    },
    {
        "wl_address": "0x816cf35d412f49f68e6be75614abfb2b40138683",
        "spots": 4
    },
    {
        "wl_address": "0xacc3478899caa1cee449cd21bcba65d5e7a34439",
        "spots": 4
    },
    {
        "wl_address": "0x4418b1fede69d7bb75ba75aa22b3476a007cef66",
        "spots": 4
    },
    {
        "wl_address": "0x778b8c794cbc5a381deff6b0b329701d3dae3617",
        "spots": 4
    },
    {
        "wl_address": "0x0bc407f3e5681aa474bc84ee94cf86667387fff0",
        "spots": 2
    },
    {
        "wl_address": "0xd245e1f427f333c4d8af8132df6d15c01f50d3f1",
        "spots": 2
    },
    {
        "wl_address": "0xb441d2223b3cfa5f68fc37124e48836982059ab4",
        "spots": 2
    },
    {
        "wl_address": "0xbcc8cb0a825c61355a460da85159f1b43d5d49ab",
        "spots": 2
    },
    {
        "wl_address": "0xb446f19c10355958a8e3d1c5260e5bc85a745b03",
        "spots": 2
    },
    {
        "wl_address": "0xbc07a284cca61304eea14ab754f767c360d9bf57",
        "spots": 2
    },
    {
        "wl_address": "0xb46c33d7ea36ae689a353a20dbea0ab384077d7a",
        "spots": 2
    },
    {
        "wl_address": "0xbb0df1bac40ecd2b5c653e165ad92e07dec9e275",
        "spots": 2
    },
    {
        "wl_address": "0xb50847681ff539fb0ff8c4a04d89fdb260f70d95",
        "spots": 2
    },
    {
        "wl_address": "0xb59d37f55a2edfe6f5fcf98ab9b34ad1aab86116",
        "spots": 2
    },
    {
        "wl_address": "0xdafc5436cabb478e35c5e74bbc5673ca3ee04252",
        "spots": 2
    },
    {
        "wl_address": "0xd537c8f98de0c60955d91d4f4609c936ef9254a9",
        "spots": 2
    },
    {
        "wl_address": "0xb22378cbb3884c69ab28c2dd929fc3bbdaf19eeb",
        "spots": 2
    },
    {
        "wl_address": "0x04e823d9c3b4e7cbe1e28081dabad4c0c7e09c82",
        "spots": 2
    },
    {
        "wl_address": "0xb2921ec8ad6e7976f0418a2af7ff7cd8ab66a761",
        "spots": 2
    },
    {
        "wl_address": "0x926aabde44fc10a3dc22eff8a8c0eeff6e018fbb",
        "spots": 2
    },
    {
        "wl_address": "0xd6df86b1ad44d276c2d16e64d748328120f43f02",
        "spots": 2
    },
    {
        "wl_address": "0x92ab472d2ec26f229a3959e945ff1a53d8b1f925",
        "spots": 2
    },
    {
        "wl_address": "0x94f60f7a91d6fb003139c8e72c18723eca0abde8",
        "spots": 2
    },
    {
        "wl_address": "0x0a4adadc3c955ac456778ff3199df9e3359b9364",
        "spots": 2
    },
    {
        "wl_address": "0xbf9d29e5278411bcab31795398d0de0eeef4af8d",
        "spots": 2
    },
    {
        "wl_address": "0xa1e0f603a15d0a646048b1b141fd9bf2cf6a0309",
        "spots": 2
    },
    {
        "wl_address": "0x9bc475aaebef13d7af471ee8285f731e382c81f6",
        "spots": 2
    },
    {
        "wl_address": "0xceab4a98d9bb9674925664f1c7cb215daf009f8f",
        "spots": 2
    },
    {
        "wl_address": "0xa399ca6d4116d4716d19100c5278b07e5ecde840",
        "spots": 2
    },
    {
        "wl_address": "0xa4bea1495825a579febf15d7ea20a50a6cd705e7",
        "spots": 2
    },
    {
        "wl_address": "0xca0618fc251a2836805249cdb3227f254fa59cd5",
        "spots": 2
    },
    {
        "wl_address": "0xc955479b96be663b88980b05e13fbb29e123fca8",
        "spots": 2
    },
    {
        "wl_address": "0x0800833a3706db6fbbd846d5d1b9370a79af8097",
        "spots": 2
    },
    {
        "wl_address": "0xa1a6e7951e596871faeace0970906e003707ddf3",
        "spots": 2
    },
    {
        "wl_address": "0xa152eb75aa843f5b3ed32bb21991f740efb97e36",
        "spots": 2
    },
    {
        "wl_address": "0xa8d75ff8b473302e4f284b4fa7780ac8e1d5df52",
        "spots": 2
    },
    {
        "wl_address": "0xc75356f7fa15b236c6381aa34178597996b52178",
        "spots": 2
    },
    {
        "wl_address": "0xa036bb54d0fab837843df18400a89e10d1d2fe64",
        "spots": 2
    },
    {
        "wl_address": "0xcce723a788d1c24d4468031d0a73d1f76b0194f6",
        "spots": 2
    },
    {
        "wl_address": "0xc628061025b274b83df5bf5d61a8528337b201f1",
        "spots": 2
    },
    {
        "wl_address": "0xce8c8141bb0575ac14a2af40114fd65e50caa713",
        "spots": 2
    },
    {
        "wl_address": "0xa96cc62a47e74ab8680f14a1e2003bbd6c9884ce",
        "spots": 2
    },
    {
        "wl_address": "0xd18d3efbd6f4d4b6ddeeef6ab1618428a656770f",
        "spots": 2
    },
    {
        "wl_address": "0xa02e128bd1c43414d4ea6acb20920b249b7281ac",
        "spots": 2
    },
    {
        "wl_address": "0xadd699d758e493044679dbdbb4d5e4d518075cd9",
        "spots": 2
    },
    {
        "wl_address": "0xc41c1f08cc0bf78b370207d1d7dff03de65b0df8",
        "spots": 2
    },
    {
        "wl_address": "0x9eff2641cb6f268e634e7f689d23cba2ea847b57",
        "spots": 2
    },
    {
        "wl_address": "0xd02f75e4bbc9c97bfd444a7193c31ccba19c8ccf",
        "spots": 2
    },
    {
        "wl_address": "0x9eb9542f90c55b88365c4dd2e59eab9b91bc6dff",
        "spots": 2
    },
    {
        "wl_address": "0x9e7ceff7db1aafb8b6314658aa060f574edc3415",
        "spots": 2
    },
    {
        "wl_address": "0x9e054f52404d30fb87d4a0d6406fa150538e2179",
        "spots": 2
    },
    {
        "wl_address": "0xd0fbb89151916cbdcb3e75a79b599b36aafb63cc",
        "spots": 2
    },
    {
        "wl_address": "0xd1062525b544c2c283efa4e1cd05c719cc3beb97",
        "spots": 2
    },
    {
        "wl_address": "0xc1a3a5c69e2a76f80805f89a62f107f7a3f9c23a",
        "spots": 2
    },
    {
        "wl_address": "0x9cdfef7755376e6a6e3c6d09f74ea0ad05e90523",
        "spots": 2
    },
    {
        "wl_address": "0x1c0e850571da43570880f9adf134f801485f74b4",
        "spots": 2
    },
    {
        "wl_address": "0xdb4949d4ff2400e4cd595cf0eec9b560cf1024a8",
        "spots": 2
    },
    {
        "wl_address": "0xdd990eac4b0204d9046eecdfe30980e273fb64a6",
        "spots": 2
    },
    {
        "wl_address": "0x01d672af179b898f9a6c48cf423229d03c248be0",
        "spots": 2
    },
    {
        "wl_address": "0x010ee5c07bc00aac0a17b8bdd05c21d100a0df1b",
        "spots": 2
    },
    {
        "wl_address": "0xf8001af2a876c38096d87e047790f29fd60b1811",
        "spots": 2
    },
    {
        "wl_address": "0xf7cd943c9f465e75dbaf9a34bc7bdcfda65ad937",
        "spots": 2
    },
    {
        "wl_address": "0xf726b93eb4ab1f4cc60f904d8a0e98b95b4e2520",
        "spots": 2
    },
    {
        "wl_address": "0xf71e61bbec7f4a1b5d75f4cb3c5da7697db2f174",
        "spots": 2
    },
    {
        "wl_address": "0xf6842e34a04a42f48251e143af75ed475451b287",
        "spots": 2
    },
    {
        "wl_address": "0xf5ec5f3bdda4a1802358ee9a2dbba262dd29a7bc",
        "spots": 2
    },
    {
        "wl_address": "0xf1ecaaf33665147b5badb74d4fc636d99dcaa2c0",
        "spots": 2
    },
    {
        "wl_address": "0xee9219f9f836f15423c8146bf138a4fdf92a105e",
        "spots": 2
    },
    {
        "wl_address": "0xee7df5816db6054d194614d1949531f72e1ad59c",
        "spots": 2
    },
    {
        "wl_address": "0xebe05c2d73e2b8f558018ef9127374cc937c0348",
        "spots": 2
    },
    {
        "wl_address": "0x90fdf233d48b4c5929441043ad49fb78b2e2bead",
        "spots": 2
    },
    {
        "wl_address": "0x0224760094b3e865e39d1d2b7210e22887554432",
        "spots": 2
    },
    {
        "wl_address": "0xe9c437ef122f261d2358f0e403b208bd2cd309d3",
        "spots": 2
    },
    {
        "wl_address": "0xe9a440c30723055555aba5830d142f52d0e733fd",
        "spots": 2
    },
    {
        "wl_address": "0xe7a85df9c54d543cae0e95e54a72c59e0b3bb2ce",
        "spots": 2
    },
    {
        "wl_address": "0x023cf85b4b860caa294d518e7e1f50b45361f3db",
        "spots": 2
    },
    {
        "wl_address": "0xe6c9819efbcc89c8768796461bc464a181871b9e",
        "spots": 2
    },
    {
        "wl_address": "0xe61459c7fecf9aeb93f9d824aef521d046476b5a",
        "spots": 2
    },
    {
        "wl_address": "0x0306fec44916a14d4d0a9576bb2359ee2076fe68",
        "spots": 2
    },
    {
        "wl_address": "0xe33ccf2e727c1dd2c8fda36e357c3617c1e7448f",
        "spots": 2
    },
    {
        "wl_address": "0xe2fb7cf5043dcda8262177afb06316a6072796fe",
        "spots": 2
    },
    {
        "wl_address": "0xdf9a20446d7ba83d84d0ea9a8c45953f7f5b6866",
        "spots": 2
    },
    {
        "wl_address": "0x03f60667b922ca64f68034fb5819e84aaed5d6b7",
        "spots": 2
    },
    {
        "wl_address": "0x0489d46075e4ec454df624968610a14c5524d1dd",
        "spots": 2
    },
    {
        "wl_address": "0xddad7e65e342a5bc030d23f2fba8d3d2d2bbedc3",
        "spots": 2
    },
    {
        "wl_address": "0xfcd86538466f2cd6ee548f9150209820de4e70c7",
        "spots": 2
    },
    {
        "wl_address": "0xeb310c52a76486aa62405de5f1af64715d8caaac",
        "spots": 2
    },
    {
        "wl_address": "0xacec286880c3783a84d0027a36d78410870d1a1e",
        "spots": 2
    },
    {
        "wl_address": "0x907e6cf2f3fc98dbe941f27da6306615ea391a64",
        "spots": 2
    },
    {
        "wl_address": "0x56ef675c30139451656123f12fa31590b9d4662d",
        "spots": 2
    },
    {
        "wl_address": "0x6d424cd4ea7f54eb0b21f34844ef820efa091b09",
        "spots": 2
    },
    {
        "wl_address": "0x6cfecad7599b4839221959190f4f5db9c24b62eb",
        "spots": 2
    },
    {
        "wl_address": "0x6b27d5fd7151ac00f5fa6ec86e682cc5ef58bc3a",
        "spots": 2
    },
    {
        "wl_address": "0x6629a8eb901c4b792913807871fd847c19b48d4c",
        "spots": 2
    },
    {
        "wl_address": "0x6610a145f6f12c27187986ca0e80df4890a38c19",
        "spots": 2
    },
    {
        "wl_address": "0x659f1dd1a161087153b939394288f3fbfde2862e",
        "spots": 2
    },
    {
        "wl_address": "0x650a4c197dffffb79e25a80b1649ad8c8937168c",
        "spots": 2
    },
    {
        "wl_address": "0x61033679afe881173bd9955632773407f204d6c9",
        "spots": 2
    },
    {
        "wl_address": "0x60cf8459087e329187aeb5a30f063e6effa812ad",
        "spots": 2
    },
    {
        "wl_address": "0x605e644194e17aadff4c60f1502c4dc555ddbdb9",
        "spots": 2
    },
    {
        "wl_address": "0x5fe8d68c2f6097f39606b89801072fe40f744a32",
        "spots": 2
    },
    {
        "wl_address": "0x5dfea44d0c0612691abfd626519d44e982936769",
        "spots": 2
    },
    {
        "wl_address": "0x5c7d91de3bf6ef7436892ecd4738b9eca8c3da90",
        "spots": 2
    },
    {
        "wl_address": "0x5add6b5bcf8b024ca657bbc63b7cb8d46a011164",
        "spots": 2
    },
    {
        "wl_address": "0x5a375e92d0724ea7e39181439ba423afbafba9d9",
        "spots": 2
    },
    {
        "wl_address": "0x58995c728009a24cafae9567d75dee957ed020cc",
        "spots": 2
    },
    {
        "wl_address": "0x57b1439d16e7c9764fe98ce3e96243635fa7bd45",
        "spots": 2
    },
    {
        "wl_address": "0x6d795cbeb41073b9a51dcf10bde8ca6bbfd62aa1",
        "spots": 2
    },
    {
        "wl_address": "0x6e7244d0d1d32b74e7e7f6e97d08e5e1617570cd",
        "spots": 2
    },
    {
        "wl_address": "0x6e85700d08fabbc3bfa7d189e04c5be91bac9010",
        "spots": 2
    },
    {
        "wl_address": "0x7705ccfc1e1aaf09d24070f17e6eb64629e0f361",
        "spots": 2
    },
    {
        "wl_address": "0x7b90a9ae2fa3f0787f6e7b9395ba3cc732ed0f48",
        "spots": 2
    },
    {
        "wl_address": "0x7b701063ab05c8c887e99d6af266a35121d3b71a",
        "spots": 2
    },
    {
        "wl_address": "0x7b006bc7cb343b51cd45becaba74c0f89b258163",
        "spots": 2
    },
    {
        "wl_address": "0x7a1efc07829c52a20d0f7b6d7409b00f7be56e3c",
        "spots": 2
    },
    {
        "wl_address": "0x1107602d51193953cfe4cb8a4b01d846e7e426ab",
        "spots": 2
    },
    {
        "wl_address": "0x780aa491259a88960c843e8e5cc7bb887ee63337",
        "spots": 2
    },
    {
        "wl_address": "0x76c30a8a887baf8fa262d40942811b9b082fa7da",
        "spots": 2
    },
    {
        "wl_address": "0x70098e7ecf820edf30a81932d9bec12d3ad48d88",
        "spots": 2
    },
    {
        "wl_address": "0x76b8754c6715de94b2461279162aa7b0857642b1",
        "spots": 2
    },
    {
        "wl_address": "0x76abccf3ee9f64562ac4a698828e2c72825283d1",
        "spots": 2
    },
    {
        "wl_address": "0x75996d5b947fc3608de4acfaf0d78ed25b081a56",
        "spots": 2
    },
    {
        "wl_address": "0x738a98b422671e2520212767f5aa8ed49467f6bd",
        "spots": 2
    },
    {
        "wl_address": "0x112db64120195c1deea10f86db259ca88b526d80",
        "spots": 2
    },
    {
        "wl_address": "0x70ddc66b2a93df33195e2d7205a7eafe3fb3bca7",
        "spots": 2
    },
    {
        "wl_address": "0x13b3c8e41544dec758ffb0ac5395e1ddbeaa97da",
        "spots": 2
    },
    {
        "wl_address": "0x4f4b5d02e50e2224258a10354fa6ec054b4fb939",
        "spots": 2
    },
    {
        "wl_address": "0x7eb9448a6564cf9df58ab65a655c0ad6909269b9",
        "spots": 2
    },
    {
        "wl_address": "0x4e2e7f1cdcf1b46ad9f6d73c7fd3aa4a336d2407",
        "spots": 2
    },
    {
        "wl_address": "0x180b652169c93fa5bff2989dc05ab69f8e593222",
        "spots": 2
    },
    {
        "wl_address": "0x35a925b6ba0b4b9b67dd31381f1473c704a39911",
        "spots": 2
    },
    {
        "wl_address": "0x33db45264a9ef33082cad0b62b375a5e9100b900",
        "spots": 2
    },
    {
        "wl_address": "0x31cbed3b2b4d6375ff41592bc1615b4ae989d734",
        "spots": 2
    },
    {
        "wl_address": "0x2d1b16abb878dae1c94e440bebdcacdb573e8b37",
        "spots": 2
    },
    {
        "wl_address": "0x2cd6ccd849e304b5c8fe3584df851034e7b88f83",
        "spots": 2
    },
    {
        "wl_address": "0x18f00e48ef364ec6b47417f1c00ee77caaca31d7",
        "spots": 2
    },
    {
        "wl_address": "0x2a6606f53c58c377f141c6ba047c48577a3696f0",
        "spots": 2
    },
    {
        "wl_address": "0x278361e85dddf9aa5da32d294fde62b25cbbebbe",
        "spots": 2
    },
    {
        "wl_address": "0x277b2ff9909eadee4b7d5f6b8328ddd041e52ed9",
        "spots": 2
    },
    {
        "wl_address": "0x2676aaa3b955875d86cbf0ca7224c2cef7c3cc0c",
        "spots": 2
    },
    {
        "wl_address": "0x2530babefcfad989d1f8b14ba96c04050a644d53",
        "spots": 2
    },
    {
        "wl_address": "0x22aa6d174d3ce3379db1ea8ffe051e3895ea2792",
        "spots": 2
    },
    {
        "wl_address": "0x20ce0c0f284219f4e0b68804a8333a782461674c",
        "spots": 2
    },
    {
        "wl_address": "0x1e5a8aeaa3adac6656f7d30e5f56db2a319fd177",
        "spots": 2
    },
    {
        "wl_address": "0x1e37ee6c917342ed502bbee603e3732576623409",
        "spots": 2
    },
    {
        "wl_address": "0x36a2f588c8e7ba35d62db426b8bf1481b067c385",
        "spots": 2
    },
    {
        "wl_address": "0x17ff436078a758b89fdb792fba89a5ac1fb48df7",
        "spots": 2
    },
    {
        "wl_address": "0x3ec420990e0ec7179fd6767c79e733b842e8c3fc",
        "spots": 2
    },
    {
        "wl_address": "0x4983bfb63533b9f9ec04dd9a83b00c4f5da35863",
        "spots": 2
    },
    {
        "wl_address": "0x14c837892f33d5a8991e928c0795d8b7539827f9",
        "spots": 2
    },
    {
        "wl_address": "0x47d6f5a440bc8d9618e84f6009fa967b8cf24b8d",
        "spots": 2
    },
    {
        "wl_address": "0x4756612a149f8bd3bc3f25e10b370634cde204b7",
        "spots": 2
    },
    {
        "wl_address": "0x15e5552fb71a35e0b1ea1802f7cb4a810ae6da03",
        "spots": 2
    },
    {
        "wl_address": "0x3ef5bc4673c5f127fdfedaca3865f09cab04e3d9",
        "spots": 2
    },
    {
        "wl_address": "0x3dd29824a2e2907c829f8ee4b5225741de0cc58d",
        "spots": 2
    },
    {
        "wl_address": "0x38a98ce971863858fc594add1b70bc3458bd4c99",
        "spots": 2
    },
    {
        "wl_address": "0x3bebb60d774042531e3d4765c6b91fee05f0bf8c",
        "spots": 2
    },
    {
        "wl_address": "0x17cff82510dbf467679a3c9229137fe77aee79d3",
        "spots": 2
    },
    {
        "wl_address": "0x3ae45fdfa6bde2c756f113b7f9d3a29038a17fb4",
        "spots": 2
    },
    {
        "wl_address": "0x3a3003af64201ebf8955d3dba2804d78d920075b",
        "spots": 2
    },
    {
        "wl_address": "0x39fdf80565bbbbee7868bd698a8e9163e46f4b1b",
        "spots": 2
    },
    {
        "wl_address": "0x399bfe6177bc8356741d4661f62f6234a17faaaf",
        "spots": 2
    },
    {
        "wl_address": "0x7bc03f0272339e7ef984865a40e337dc53a96d7f",
        "spots": 2
    },
    {
        "wl_address": "0x002903f603e9b1dfd5fc709613dfe846ce7e8186",
        "spots": 2
    },
    {
        "wl_address": "0x81498b7e530e19a3fd9466047bcdc30a8c9626fa",
        "spots": 2
    },
    {
        "wl_address": "0x80c02a282cb813304f330de7d1d1fcb712eb3c7a",
        "spots": 2
    },
    {
        "wl_address": "0x8469bfe25390a207f17d1028ce763c918fa2b449",
        "spots": 2
    },
    {
        "wl_address": "0x81e58eb3903812ec3c512b7d90a7f58d8351948b",
        "spots": 2
    },
    {
        "wl_address": "0x867318b8b7af9dc9246d8de112ce371f78ae91c8",
        "spots": 2
    },
    {
        "wl_address": "0x0ca4c84e969490ccd499c3cb667b691592002d44",
        "spots": 2
    },
    {
        "wl_address": "0x86887b6c5d95d179e318c4c59c38a95f60a8c11d",
        "spots": 2
    },
    {
        "wl_address": "0x8479f2ac6a9d94708fe5831cf6b7678ad0fdefd0",
        "spots": 2
    },
    {
        "wl_address": "0x0e56280380849fd6df7c483d9db3fdff363806d0",
        "spots": 2
    },
    {
        "wl_address": "0x8d94bb7e4c7505fb1157958bd28a1b396d04b58b",
        "spots": 2
    },
    {
        "wl_address": "0x8d333a961ce17efbf8cae047b87ff0ebef41b01c",
        "spots": 2
    },
    {
        "wl_address": "0x8e2d345252ae719596e4b43b1719bc8f4ce050fa",
        "spots": 2
    },
    {
        "wl_address": "0x84b5deebc3669d600d4e05aa24957436e5d957fc",
        "spots": 2
    },
    {
        "wl_address": "0x8e0b9b962fc634bb00a3630ad6ce3170127fb06c",
        "spots": 2
    }
]




