/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faDiscord, faTwitter, faMedium} from '@fortawesome/free-brands-svg-icons';
import {faEarth} from "@fortawesome/free-solid-svg-icons";

import './css/SocialLinks.scss';

const socialLinks: { icon: IconProp, url: string }[] = [
    {icon: faEarth, url: 'https://www.hidethefish.com'},
    {icon: faDiscord, url: 'https://discord.gg/hiddenfishdao'},
    {icon: faTwitter, url: 'https://twitter.com/hiddenfishdao'},
    {icon: faMedium, url: 'https://blog.hidethefish.com'},
];


const SocialLinks = ({...props}) => {
    return (
        <Row {...props} className='justify-content-center'>
            <Col className='social-links col-xl-8 col-lg-10 col-md-12 col-sm-10 pt-2'>
                {socialLinks.map((l, i) => {
                    return (
                        <a key={i} href={l.url} target='_blank' rel='noopener'>
                            <FontAwesomeIcon icon={l.icon} size="xl"/>
                        </a>
                    )
                })}
            </Col>
        </Row>
    );
}

export default SocialLinks;
